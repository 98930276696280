import { withDependencies } from '@wix/thunderbolt-ioc'
import { SdkHandlersProvider } from '@wix/thunderbolt-symbols'
import { INavigation, NavigationSymbol } from 'feature-navigation'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'
import { IUrlChangeListener } from 'feature-router/src/types'

export const locationWixCodeSdkHandlersProvider = withDependencies(
	[NavigationSymbol, UrlHistoryManagerSymbol],
	(navigation: INavigation, urlHistoryManager: IUrlHistoryManager): SdkHandlersProvider<any> & IUrlChangeListener => {
		const onChangeHandlers: Array<Function> = []

		return {
			getSdkHandlers: () => ({
				navigateTo: navigation.navigateTo,
				pushUrlState: (href: string): void => {
					const url = new URL(href)
					urlHistoryManager.pushUrlState(url)
				},
				registerLocationSdkOnChangeHandler: (handler: Function) => {
					onChangeHandlers.push(handler)
				},
			}),
			onUrlChange: (url) => {
				onChangeHandlers.forEach((handler) => handler(url.href))
			},
		}
	}
)
